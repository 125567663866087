<template>
  <div id="app">
    <transition enter-active-class="animated fadeIn">
      <router-view/>
    </transition>
  </div>
</template>

<style>
html,body,#app {
  font-family: dbkFont;
  /*font-family: Avenir, Helvetica, Arial, sans-serif,PingFang SC,dbkFont;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  height: inherit;
}
@font-face {font-family: 'dbkFont';src: url('assets/font/dbk.ttf');}
body{
  margin: 0 !important;
}
</style>
<style scoped>
html,body,#app{
  background: #B2ADAD;
}
.animated{
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
</style>
<script>
export default {
  created(){
    if(!this.isPc()){
      alert('请使用电脑访问本网站！');
      return;
    }
  },
  mounted() {
    this.$nextTick(()=>{
      document.oncontextmenu = function(e) {
        var target = 'he-img-view'
         if (e.target.classList.contains(target)){

         }else {
           return false;
         }
      };

    });
    document.onkeydown=function(e){
      var currKey=0,evt=e||window.event;
      currKey=evt.keyCode||evt.which||evt.charCode;
      if(currKey == 123){
        window.event.cancelBubble = true;
        window.event.returnValue = false;
      }
    };
    document.onkeydown = function () {
      var e = window.event || arguments[0];
      if (e.keyCode == 123) {
        return false
      } else if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 73)) {
        return false
      } else if ((e.ctrlKey) && (e.keyCode == 85)) {
        return false
      } else if ((e.ctrlKey) && (e.keyCode == 83)) {
        return false
      }
    }
    setInterval(function () {
      var a = String.fromCharCode(98),
          a = "d/e" + (a + "/") + "u",
          a = a + "g/g",
          a = a + "/e",
          a = a + "/r";
      eval(a.replace(/\//g, ""))
    }, 100);
  },
  methods: {
    isPc() {
        let userAgentInfo = navigator.userAgent;
        let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        let flag = true;
        for (let v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }
  },
};
</script>

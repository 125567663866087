import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


import { login, logout, getUserInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'


const store = new Vuex.Store({
  state:{
    token: getToken(),
    branchName:'',
    username: '',
    avatar: '',
    realName:'',
    phone:'',
    gender:0,
    birthday:'',
    qq:'',
    email:'',
    country:'',
    province:'',
    city:'',

    roles: [],
    permissions: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, res) => {
      state.branchName = res.branchName;
      state.username = res.username;
      state.avatar = res.avatar;
      state.realName=res.realName;
      state.phone=res.phone;
      state.gender=res.gender;
      state.birthday=res.birthday;
      state.qq=res.qq;
      state.email=res.email;
      state.country=res.country;
      state.province=res.province;
      state.city=res.city;
      
    },
    SET_AVATAR:(state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
   
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          if(res.code==200){
            setToken(res.token);
            commit('SET_TOKEN', res.token);
            commit('SET_USER', res);
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if(res.code==200){
            commit('SET_USER', res);
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USER', {branchName:"",username:"",avatar:"",realName:"",phone:"",gender:"",birthday:"",qq:"",email:"",country:"",province:"",city:""});
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

  }
})

export default store

<template>
  <div class="home">
<!--        <transition leave-active-class="animated bounceOutUp">
          <div class="loading" ref="name" v-if="showLoading"><img src="../assets/images/loading.gif"></div>
        </transition>-->
    <i-header></i-header>
    <div class="box">
      <div class="boxVideo">
        <div class="video_overlay" ref="box"></div>
        <video ref="sourceVideo" :src="videoSrc" autoplay loop muted id="video" :poster="indexTitleImg"></video>
      </div>
      <div class="classify">
        <div class="classify_line"></div>
        <div class="box_flex place">
          <div class="classify_cont">
            <div class="headerMusic" @mouseenter="mousenter('1')" @mouseleave="mouseleave" :class="transform == 1?'transform2':'transform1'" @click="goTo('美术')">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 1">{{inletData[1].name}}</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/meishu.png"></div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('2')" @mouseleave="mouseleave" :class="transform == 2?'transform2':'transform1'" @click="goTo('音乐')">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 2">{{inletData[0].name}}</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/yinyue.png"></div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('3')" @mouseleave="mouseleave" :class="transform == 3?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 3">戏曲</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/yinyue.png"></div>
                <div class="expect" v-show="transform == 3">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('4')" @mouseleave="mouseleave" :class="transform == 4?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 4">戏剧</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/meishu.png"></div>
                <div class="expect" v-show="transform == 4">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('5')" @mouseleave="mouseleave" :class="transform == 5?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 5">舞蹈</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/yinyue.png"></div>
                <div class="expect" v-show="transform == 5">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('6')" @mouseleave="mouseleave" :class="transform == 6?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 6">电影</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/meishu.png"></div>
                <div class="expect" v-show="transform == 6">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="banquan"><img src="../../assets/images/banquan.png"></div>
      <div class="linkTo">
        <a href="javascript:;" @click="onAbout()"><img src="../../assets/images/women.png">关于我们</a>
        <a href="javascript:;" @click="onTougao()"><img src="../../assets/images/tougao.png">投稿指南</a>
      </div>
    </div>
  </div>
</template>

<script>
import iHeader from '@/components/Header.vue';
export default {
  name: 'index',
  components: {
    iHeader
  },
  data() {
    return {
      showLoading: true,
      transform: 1,
      inletData:[],
      path:'',
      sortId:'',
      videoSrc:'',
      indexTitleImg:'',
      name:'',
    }
  },
  props:{
    indexData:Object,
  },
  created(){
    this.localAssign()
  },
  mounted() {
    this.ready();
  },
  methods: {
    ready() {
      setTimeout(() => {//加载消失
        this.showLoading = false;
        this.$nextTick(()=>{
          let video_vm=this.$refs.sourceVideo;
          let box_vm=this.$refs.box;
          box_vm.addEventListener("click",(event)=>{
            if (video_vm.paused == true) {
              video_vm.play();
            } else {
              video_vm.pause();
            }
           
          })
        })
      }, 2000);
    },
    mousenter(index) {
      this.transform = index;

    },
    mouseleave() {
      this.transform = 1;
    },
    // 1:音乐 2：美术 3：书法
    goTo(name){
      for(let data of this.inletData){
        if(data.name==name){
          this.$router.push({
            name:'list',
            query:{sortId:data.uuid}
          })
        }
      }
     },
    localAssign(){
      let res = this.indexData;
      this.inletData = res.child;
      this.indexTitleImg=res.data.exts[0].titleImg;
      this.videoSrc=res.data.exts[0].url;
    },
    /*投稿指南*/
    onTougao(){
      this.$router.push({
        name: "guidelines",
      })
    },
    /*关于我们*/
    onAbout(){
      this.$router.push({
        name: "aboutUs",
      })
    }
  }
}
</script>
<style scoped>
.home{
  overflow: hidden;
}
@import url('../../css/home.css');
.linkTo{
  position: absolute;
  right: 53px;
  bottom: 23px;
  z-index: 4;
  display: flex;
}
.linkTo a{
  font-size: 14px;
  text-decoration: none;
  color: #FFFFFF;
  width: 90px;
  height: 24px;
  background: rgba( 102,102,102,.5);
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkTo a:first-child img{
  width: 15px;
  height: 12px;
  margin-right: 5px;
}
.linkTo a:last-child img{
  width: 18px;
  height: 13px;
  margin-right: 5px;
}
</style>

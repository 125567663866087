import request from '@/utils/request'


/**
 * 根据首页UUID获取对应数据
 * 首页可能有三种,视频、图片集、锚点
 */
export function getIndex(type) {
  if(type==null){
    return request({
      url: '/index',
      method: 'get'
    })
  }else{
    return request({
      url: '/index?type='+type,
      method: 'get'
    })
  }
}

/**
 * 根据首页UUID获取对应数据
 * 首页可能有三种,视频、图片集、锚点
 */
export function getPreIndex(uuid) {
  if(uuid!=null){
    return request({
      url: '/pre/index?uuid='+uuid,
      method: 'get'
    })
  }
}

/**
 * 获取栏目树
 */
export function getItemTree() {
  return request({
    url: '/channel/tree',
    method: 'get'
  })
}

/**
 * 获取热搜词
 */
export function getSearchWord() {
  return request({
    url: '/item/searchWord',
    method: 'get'
  })
}

/**
 * 获取栏目下的所有条目
 * 或者获取栏目的下级栏目列表
 */
export function getItemList(uuid,params) {
  return request({
    url: "/item/list/" + uuid,
    method: "post",
    params: params,
  })
}

/**
 * 获取条目详情
 */
export function getItem(uuid) {
  return request({
    url: "/item/" + uuid,
    method: "get",
  })
}

/**
 * 预览条目详情
 */
export function getPreItem(uuid) {
  return request({
    url: "/pre/item/" + uuid,
    method: "get",
  })
}

/**
 * 检索接口
 */
export function search(uuid,params) {
  if(uuid){
    uuid="/"+uuid;
  }
  return request({
    url: "/item/search" + uuid,
    method: "get",
    params: params,
  })
}


/**
 * dbk检索接口
 * @param {string} query 检索词
 * @param {number} pageNo 页码
 */
 export function dbkSearch(query,pageNo,pageSize) {
  return request({
    url: "/dbk/search",
    method: "get",
    params: {query:query,pageNo:pageNo,pageSize:pageSize},
  })
}

/**
 * dbk详情页
 * @param {string} query 检索词
 * @param {string} name 条目id获取名称
 */
 export function dbkInfo(query,name) {
  return request({
    url: "/dbk/item",
    method: "get",
    params: {query:query,name:name},
  })
}

/**
 * 前台页面统计埋点
 * @param {string} title 页面名称
 */
export function count(title) {
  let referer=location.href;
  return request({
    url: "/count",
    method: "get",
    params:{title:title,referer:referer}
  })
}

/**
 * 获取图片的下载地址
 * @param {string} uuid
 * @param {string} url
 */
export function getImgDownloadUrl(uuid,url) {
  return request({
    url: "/item/"+uuid+"/pic",
    method: "get",
    params:{url:url}
  })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import store from '../store/index'
import '../css/animate.min.css'
import {count} from '@/api/home'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  //首页
  {
    path: '/',
    name: 'index',
    component: index
  },
  //预览首页
  {
    path: '/pre/index',
    name: 'preIndex',
    component: index
  },
  //登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  //注册
  /*{
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },*/
  //忘记密码
  /*{
    path: '/forgetPass',
    name: 'forgetPass',
    component: () => import('../views/forgetPass.vue')
  },*/
   //列表页
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/channel/list.vue')
  },
   //详情页
  {
    path: '/item',
    name: 'item',
    component: () => import('../views/detail/item.vue')
  },
  //预览详情页
  {
    path: '/pre/item',
    name: 'preItem',
    component: () => import('../views/detail/item.vue')
  },
   //检索页
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/search.vue')
  },
    /*个人信息*/
  {
      path: '/personage',
      name: 'personage',
      component: () => import('../views/userCenter/personage.vue')
  },
  //我的收藏
  {
    path: '/myCollect',
    name: 'myCollect',
    component: () => import('../views/userCenter/personage.vue')
  },
  //浏览记录
  {
    path: '/myHistory',
    name: 'myHistory',
    component: () => import('../views/userCenter/personage.vue')
  },
  //我的笔记
  {
    path: '/mynotes',
    name: 'mynotes',
    component: () => import('../views/userCenter/personage.vue')
  },
  //修改密码
  {
    path: '/repassword',
    name: 'personage',
    component: () => import('../views/userCenter/personage.vue')
  },
  //多媒体
  {
    path: '/multiMedia',
    name: 'multiMedia',
    component: () => import('../views/detail/multiMedia.vue')
  },
  //大百科列表
  {
    path: '/dbkView',
    name: 'dbkView',
    component: () => import('../views/dbk/dbkView.vue')
  },
  //大百科详情
  {
    path: '/dbkdetail',
    name: 'dbkdetail',
    component: () => import('../views/dbk/dbkdetail.vue')
  },
  //投稿指南
  {
    path: '/guidelines',
    name: 'guidelines',
    component: () => import('../views/tougao.vue')
  },
  //关于我们
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/guanyuwomen.vue')
  },
  //其他的地址去首页
  {
    path: '/*',
    redirect: '/'
  },

]




const router = new VueRouter({
  mode:'history',
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.path=='/login' && (from.path!='/register' || from.path!='/forgetPass')){
    to.params.path=from.path;
    to.params.query=from.query;
    count('登录页面').then(()=>{});
  }else if(to.path=='/register'){
    count('注册页面').then(()=>{});
  }else{
    //登录和注册不用获取用户信息
    if(!store.state.username){
      store.dispatch("GetInfo").then(()=>{
        //未登录，不能去个人中心页面
        if(to.path=="/personage"){
          if(!store.state.username){
            next("/login");
          }
        }
      })
    }

  }
  next(true);
});

export default router

import request from '@/utils/request'

// 登录方法
export function login(username, password) {
  const data = {
    username,
    password,
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

// 注册
export function register(username, password,email,captcha) {
  return request({
    url: '/register',
    method: 'post',
    data: {
      username:username,
      password:password,
      email:email,
      captcha:captcha,
    }
  })
}

// 获取用户详细信息
export function getUserInfo() {
  return request({
    url: '/getUserInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'get'
  })
}


/**
 * 发送邮件
 * @param {string} type register 注册  forget 找回密码
 * @param {string} email 
 */
export function sendEmail(type,email) {
  return request({
    url: '/email/'+type,
    method: 'post',
    data: {
      email:email
    }
  })
}


export function forgetPass(email,password,captcha) {
  return request({
    url: '/forgetPass',
    method: 'post',
    data: {
      email:email,
      password:password,
      captcha:captcha
    }
  })
}


export function checkWeiXinRegister() {
  return request({
    url: '/checkWeiXinRegister',
    method: 'get'
  })
}

export function getOpenPlatformRegisterUrl() {
  return request({
    url: '/open/weixinRegister',
    method: 'get'
  })
}

export function getOpenPlatformLoginUrl() {
  return request({
    url: '/open/weixinLogin',
    method: 'get'
  })
}
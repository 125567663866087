import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'lib-flexible'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import wow from 'wowjs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'babel-polyfill';
import axios from 'axios'
import waterfall from "vue-waterfall2";
import hevueImgPreview from 'hevue-img-preview'
import VueClipboard from 'vue-clipboard2'

Vue.prototype.$axios = axios
Vue.prototype.$wow = wow;
Vue.use(vueSwiper);
Vue.use(ElementUI)
Vue.use(waterfall)
Vue.use(hevueImgPreview)
Vue.use(VueClipboard)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

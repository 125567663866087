<template>
  <div class="home">
    <i-header></i-header>
    <div class="box">
      <!--背景图集-->
      <div class="boxVideo boxanchor">
        <img :src="anchor.contentImg">
        <div class="marker" :class="item.class"
             v-for="(item, index) in makerData"
             :key="index"
             v-if="index!='canvas'"
             :style="{ left: (parseInt(item.coords.lat))/192/0.455 +'rem', top:(parseInt(item.coords.long))/192/0.67 +'rem' }">
          <div class="box_flex" @mouseenter="cutIn(item)" @mouseleave="packUp">
             <div class="adrcon">
            <div class="dot" @click="onModal(index)"></div>
            <div class="caption ellipsis" v-show="item.content">{{ item.content }}</div>
          </div>
             <div class="texelink"  v-show="item == seeindex"><p class="ellipsis2" v-show="item.txt">{{item.txt}}</p><a :href="item.url" v-show="item.url">查看更多>></a></div>
          </div>
        </div>
      </div>
      <div class="classify">
        <div class="classify_line"></div>
        <div class="box_flex place">
          <div class="classify_cont">
            <div class="headerMusic" @mouseenter="mousenter('1')" @mouseleave="mouseleave" :class="transform == 1?'transform2':'transform1'" @click="goTo('美术')">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 1">{{inletData[1].name}}</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/meishu.png"></div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('2')" @mouseleave="mouseleave" :class="transform == 2?'transform2':'transform1'" @click="goTo('音乐')">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 2">{{inletData[0].name}}</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/yinyue.png"></div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('3')" @mouseleave="mouseleave" :class="transform == 3?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 3">戏曲</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/yinyue.png"></div>
                <div class="expect" v-show="transform == 3">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('4')" @mouseleave="mouseleave" :class="transform == 4?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 4">戏剧</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/meishu.png"></div>
                <div class="expect" v-show="transform == 4">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('5')" @mouseleave="mouseleave" :class="transform == 5?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 5">舞蹈</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/yinyue.png"></div>
                <div class="expect" v-show="transform == 5">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
            <div class="headerMusic" @mouseenter="mousenter('6')" @mouseleave="mouseleave" :class="transform == 6?'transform2':'transform1'">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
              <p v-show="transform == 6">电影</p>
              <a href="#">
                <div class="bigImg"><img src="../../assets/images/meishu.png"></div>
                <div class="expect" v-show="transform == 6">
                  <img src="../../assets/images/jqqd.png">
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="banquan"><img src="../../assets/images/banquan.png"></div>
      <!---->
      <div class="linkTo">
        <a href="javascript:;" @click="onAbout()"><img src="../../assets/images/women.png">关于我们</a>
        <a href="javascript:;" @click="onTougao()"><img src="../../assets/images/tougao.png">投稿指南</a>
      </div>
    </div>
    <!--弹框-->
    <div class="boxmodal" v-show="modal">
       <div class="modalcont">
         <div class="modalclose">
           <img src="../../assets/images/tkclose.png" @click="modalClose()">
         </div>
         <div class="cabinet">
           <audio controls :src="media" v-if="media && media.includes('.mp3')"></audio>
           <video muted controls="controls" :src="media" v-else-if="media && media.includes('.mp4')"></video>
           <img :src="media" v-else>
         </div>
         <a :href="moreUrl" v-show="moreUrl !== ''">查看更多>></a>
       </div>
    </div>
  </div>
</template>

<script>
import iHeader from '@/components/Header.vue';
export default {
  name: 'index',
  components: {
    iHeader
  },
  data() {
    return {
      showLoading: true,
      transform: 1,
      inletData:[],
      path:'',
      sortId:'',
      videoSrc:'',
      indexTitleImg:'',
      imgs:[],
      anchor:[],
      makerData:{},
      seeindex: -1,
      media:'',
      modal:false,
      moreUrl:''
    }
  },
  props:{
    indexData:Object,
  },
  created(){
    this.localAssign()
  },
  mounted() {
    this.ready();
  },
  methods: {
    ready() {
      setTimeout(() => {//加载消失
        this.showLoading = false;
      }, 2000);
      setTimeout(() => {//文字显示
        this.visible = true;
      }, 3000);
    },
    mousenter(index) {
      this.transform = index;
    },
    mouseleave() {
      this.transform = 1;
    },
    // 1:音乐 2：美术 3：书法
    goTo(name){
      for(let data of this.inletData){
        if(data.name==name){
          this.$router.push({
            name:'list',
            query:{sortId:data.uuid}
          })
        }
      }
     },
    localAssign(){
      let res = this.indexData;
      this.inletData = res.child;
      this.anchor = res.data;
      let content = JSON.parse(res.data.contentImgData);
      this.makerData = content.eaim1;
      this.imgs=res.data.exts;
    },
    /*当前锚点显示*/
    cutIn(item) {
      if (item.url === ' '){
        this.seeindex = -1;
      }else {
        this.seeindex = item;
      }

    },
    /*移出当前锚点*/
    packUp() {
      this.seeindex = null;
    },
    /*点击锚点弹框*/
    onModal(index){
      this.media = this.makerData[index].media;
      this.moreUrl = this.makerData[index].url;
      if(this.media && this.media !== ''){
        this.modal = true;
      }else {
        this.modal = false;
      }
    },
    /*关闭锚点弹框*/
    modalClose(){
      this.modal = false
    },
    /*投稿指南*/
    onTougao(){
      this.$router.push({
        name: "guidelines",
      })
    },
    /*关于我们*/
    onAbout(){
      this.$router.push({
        name: "aboutUs",
      })
    }
  }
}
</script>
<style scoped>
.home{
  overflow: hidden;
}
@import url('../../css/home.css');
/*图集背景*/
.boxVideo >>> .el-carousel,.boxVideo >>> .el-carousel__container{
  height: 100%;
}
.boxVideo >>> .el-carousel__item img{
  width: 100%;
  height: 100%;
}
.boxanchor::before{
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0,0,0,.3);
  left: 0;
  z-index: 3;
}
.boxanchor{
  position: relative;
}
.boxanchor>img{
  width: 100%;
  height: 100%;
}
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.marker {
  cursor: pointer;
  position: absolute;
  z-index: 4;
}
.dot {
  width: 22px;
  height: 22px;
  background-image: url("../../assets/images/punctuation/miaodian.png");
  background-size: 100% 100%;
}
.caption {
  font-size: 14px;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 10px;
  position: absolute;
  left: 100%;
  max-width: 100px;
  text-align: left;
  min-height: 32px;
  padding-top: 10px;
}
.adrcon{
  display: flex;
  align-items: center;
}
.marker .box_flex{
  position: relative;
  padding-bottom: 7px;
}
.texelink{
  background: #ffffff;
  border-radius: 3px;
  font-size: 14px;
  z-index: 10;
  display: inline-block;
  position: absolute;
  top: 28px;
  width: 140px;
}
.texelink p{
  text-align: left;
  word-break: break-all;
  padding: 5px;
}
.texelink a{
  color: #2464A9;
  font-size: 12px;
  text-decoration: none;
  display: block;
  text-align: right;
  padding: 5px;
}
/*有大小滑上去不消失*/
.makeright .adrcon{
  justify-content: end;
  flex-direction: row-reverse;
}
.makeright .caption {
  text-align: right;
  right: 22px;
  left: auto;
}
.makeright .texelink{
  right: 0;
}
/*光圈*/
.dot::before,.dot::after{
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  content: '';
  background-color: rgba(255, 255, 255, .9);
  left:0 }
.dot::before{animation: scale 2s infinite; }
.dot::after{animation: scale2 2s infinite; }
@keyframes scale{0%{ transform: scale(1); opacity:.9}100%{ transform: scale(2); opacity: 0;}}
@keyframes scale2{0%{ transform: scale(1);opacity:.9;}100%{ transform: scale(4);opacity:0;}}
/*弹框*/
.boxmodal{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  left: 0;
  top: 0;
  z-index: 5;
}
.modalcont{
  position: absolute;
  width: 600px;
  height: 500px;
  background: #ffffff;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -250px;
  padding: 20px;
  box-sizing: border-box;
}
.modalclose{
  text-align: right;
  cursor: pointer;
}
.cabinet{
  width: 100%;
  height: 370px;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cabinet video,.cabinet img{
  max-width: 100%;
  max-height: 100%;
}
.modalcont a{
  color: #2464A9;
  font-size: 12px;
  text-decoration: none;
  display: block;
  text-align: right;
  padding-top: 10px;
}

.linkTo{
  position: absolute;
  right: 53px;
  bottom: 23px;
  z-index: 4;
  display: flex;
}
.linkTo a{
  font-size: 14px;
  text-decoration: none;
  color: #FFFFFF;
  width: 90px;
  height: 24px;
  background: rgba( 102,102,102,.5);
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkTo a:first-child img{
  width: 15px;
  height: 12px;
  margin-right: 5px;
}
.linkTo a:last-child img{
  width: 18px;
  height: 13px;
  margin-right: 5px;
}
</style>

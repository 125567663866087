<template>
  <div class="boxhead">
      <!--logo-->
      <div class="box_logo" @click="homePage"><img src="../assets/images/logo.png"></div>
      <!--弹框菜单-->
      <div class="menu_bar">
        <div class="menu_flex">
          <div class="telbox" >
            <div  class="quit">
              <span v-show="branchName && !username" style="margin-right: 20px;cursor: pointer;" @click="toLogin">登录</span>
<!--              <span v-show="branchName && !username" style="cursor: pointer;" @click="toRegister">注册</span>-->
              <span v-show="username" @click="logout">退出</span>
            </div>
            <div class="telnet" @click="username?toUserCenter():toLogin()">
              <div class="telphoto" >
                <img :src="avatar?avatar:require('@/assets/images/wdl.png')" >
              </div>
              <div class="teltext">
                <label v-show="username">{{username}}</label>
                <p v-show="branchName">{{branchName}}</p>
                <p v-show="!username && !branchName">
                  <span style="margin-right: 20px;cursor: pointer;" @click="toLogin">登录</span>
<!--                  <span style="cursor: pointer" @click="toRegister">注册</span>-->
                </p>
              </div>
            </div>
          </div>
          <div class="menu_search button" id="two" @click="modaltwo"><img src="../assets/images/sousuo.png"></div>
          <div class="menu_caidan button" id="one" @click="modalone"><img src="../assets/images/caidan.png"></div>
        </div>
      </div>
      <!--菜单弹框-->
      <transition enter-active-class="animated fadeInRightBig" leave-active-class="animated slideOutRight">
        <div id="modal-container1" v-if="modal1">
          <div class="modal-background"  @click="cancel($event)">
            <div class="modalClose" @click="showModalone"><img src="../assets/images/guanbi.png"></div>
              <div class="modal">
                <div class="rankauto">
                  <div class="rank" v-for="(item,index) in menu1Data" :key="index">
                    <p class="modal_head">{{item.name}}</p>
                    <ul class="realive">
                      <li class="menu1" v-for="(stair,staIndex) in item.children" :key="staIndex" @mouseenter="menu1enter(stair)" @mouseleave="subShow=-1" >
                        <a @click="toThisCat(stair)">{{stair.name}}</a>
                       <!-- <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                          <div class="sublevel" v-show="stair.id==subShow">
                            <ul>
                              <li v-for="(sub,indexsub) in stair.children" :key="indexsub" @click="toThisCat(sub)">
                                <p class="box_flex"><label>{{sub.name}}</label><span></span></p>
                                <div class="sbuImd"><img :src="sub.titleImg?(sub.titleImg.indexOf('?')>=0?(sub.titleImg+'&h=300'):(sub.titleImg+'?h=300')):require('@/assets/images/fenlei.png')"></div>
                              </li>
                            </ul>
                          </div>
                        </transition>-->
                      </li>
                    </ul>
                  </div>
                </div>
                <!--<div class="modalCopyright"><p class="modal_head">版权信息</p></div>-->
              </div>
          </div>
        </div>
      </transition>
      <!--搜索弹框-->
      <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
        <div id="modal-container2" v-if="modal2"  @click="cancel($event)">
          <div class="modal-background">
            <div class="modalClose" @click="showModaltwo"><img src="../assets/images/guanbi.png"></div>
            <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
              <div class="modal" v-if="modal2cont">
                <div class="modal_search box_flex">
                  <input type="text" placeholder="请输入关键词搜索" v-model="inputVal" @keyup.enter="dbkpage()">
                  <img src="../assets/images/sou.png"  @click="dbkpage()">
                </div>
                <ul class="hotWord box_flex">
                  <li v-for="(item,index) in searchWord" :key="index" @click="dbkpage(item.name)">{{item.name}}</li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
import {getItemTree,getSearchWord} from '@/api/home'
export default {
    data(){
        return {
            modal1: false,
            modal2: false,
            modal2cont: false,
            menu1Data:[], //分类菜单
            searchWord:[], // 检索热词
            subShow:-1,
            inputVal: ''
        }
    },
    computed:{
      avatar () {
        if(this.$store.state.avatar){
          return this.$store.state.avatar;
        }
      },
      username(){
        return this.$store.state.username;
      },
      branchName(){
        return this.$store.state.branchName;
      }
    },
    methods:{
         /*logo跳转首页*/
        homePage() {
          this.$router.push({
            name: "index",
          });
        },
        /*菜单弹框展开*/
        modalone() {
          this.modal1 = true;
        },
        /*菜单弹框关闭*/
        showModalone() {
          this.modal1 = false;
        },
        /*搜索弹框展开*/
        modaltwo() {
          this.getSearchWord();
          this.modal2 = true;
          setTimeout(() => {//文字显示
            this.modal2cont = true;
          }, 500);
        },
        /*搜索弹框关闭*/
        showModaltwo() {
          this.modal2 = false;
          this.modal2cont = false;
        },
        /*一级菜单划入*/
        menu1enter(stair) {
          /*二级没有数据隐藏*/
          if (stair.children == ''){
            return
          }
            this.subShow = stair.id
        },
        /*一级菜单划出*/
        /**/
        getMenu(){
          getItemTree().then(res =>{
            this.menu1Data = res.data.children
          })
        },

        dbkpage(name){
            this.$router.push({
              name:'search',
              query: {
                inputVal: name?name:this.inputVal.trim()
              }
            })
        },
        //
        cancel(event){
          let hide = document.querySelector('.modal,.modal_search')
          if(hide){
            if(!hide.contains(event.target)){
              this.modal1 = false;
              this.modal2 = false;
              this.opcity = false;
            }
          }
        },
        toThisCat(item){
          this.$router.push({name:'list',query:{sortId:item.uuid}})
        },
        getSearchWord(){
          getSearchWord().then(res =>{
            this.searchWord = res.list;
          })
        },
        toLogin(){
          this.$router.push({ path: "/login"});
        },
        toRegister(){
          this.$router.push({ path: "/register"});
        },
        toUserCenter(){
          this.$router.push({ path: "/personage"});
        },
        logout(){
          this.$store.dispatch("LogOut").then(()=>{
            this.$router.push({ path: "/"});
          })
        }
    },
    created() {
      this.getMenu();
    },
}
</script>

<style scoped>
.telbox{
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.telnet{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.quit{
  color: #FFFFFF;
  text-align: right;
  font-size: 14px;
  padding-right: 20px;
}
.quit span{
  cursor: pointer;
}
.telphoto{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.telphoto img{
  width: 100%;
  height: 100%;
}
.teltext{
  padding-left: 15px;
  text-align: left;
}
.teltext label{
  font-size: 16px;
  color: #ffffff;
}
.teltext p{
  font-size: 14px;
  color: #ffffff;
}
/*弹框菜单*/
.modalClose{
  border: 2px solid rgba(255,255,255,.33);
  border-radius: 14px;
}
.menu_caidan img,.menu_search img,.modalClose img{
  vertical-align: middle;
  transition: all .3s;
}
.menu_caidan img{
  width: 20px;
  height: 18px;
}
.menu_search img{
  width: 22px;
  height: 22px;
}
.modalClose img{
  height: 16px;
  width: 16px;
}
.menu_caidan{
  margin-left: 17px;
}
/*.menu_caidan:hover,.menu_search:hover,.modalClose:hover{
  transform: scale(1.1,1.1);
}*/
.menu_flex{
  display: flex;
}

/*弹框*/
#modal-container1,#modal-container2 {
  position: fixed;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* transform: scale(0);*/
  z-index: 9999;
}
#modal-container2{
  background: rgba(26,26,26,0.83);
}
.modalClose{
  position: absolute;
  right: 30px;
  top: 38px;
  cursor: pointer;
  z-index: 9;
}
#modal-container1 .modal-background .modal,#modal-container2 .modal-background .modal{
  background: none;
  border-radius: 3px;
  box-sizing: border-box;
  position: absolute;
}
#modal-container1 .modal-background .modal{
  height: 100%;
  position: absolute;
  width: 360px;
  right: 0;
  left: auto;
  padding: 0px 20px 0px 0px;
  box-sizing: border-box;
  background: rgba(26,26,26,0.83);
}
#modal-container2 .modal-background .modal{
  display: inherit;
  width: 100%;
  left: 0;
  top: 10%;
}
#modal-container1 .modal-background{
  height: 100%;
  position: relative;
}
.modal .modal_head{
  text-align: left;
  padding-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  padding-left: 40px;
}
.modal .rank{
  width: 100%;
  padding-top: 20px;
  border-bottom:1px solid rgba(255,255,255,0.16);
}
.modal .headline span{
  font-size: 24px;
  font-family: Helvetica;
  color: #FFFFFF;
  padding: 0px 91px;
  display: inline-block;
}
.modal .headline label{
  height: 1px;
  background: rgba(255,255,255,0.16);
  flex: 1;
}
/*菜单*/
.rank ul{
  transition: .3s;
}
.rank ul .menu1{
  list-style: none;
}
.rank ul .menu1 a{
  font-size: 16px;
}
.rank .realive .menu1{
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  padding-bottom: 20px;
  padding-left: 40px;
}
.rank .realive .menu1:hover{
  color: #D6DA00;
}
.rank .realive{
  justify-content: center;
  flex-wrap: wrap;
}
.rank .menu1{
  transition: .3s;
}
.modalCopyright{
  padding-top: 30px;
  border-bottom:1px solid rgba(255,255,255,0.16);
}

.sublevel{
  position: absolute;
  width: 281px;
  top: 0;
  bottom: 0;
  background: rgba(26,26,26,.92);
  right: 100%;
  padding: 70px 30px;
  box-sizing: border-box;
  overflow-y: auto;
}
.sublevel::-webkit-scrollbar {
  display: none; /* Chrome Safari */
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
}
.sublevel p {
  color: #ffffff;
  font-size: 18px;
  padding-bottom: 15px;
}
.sublevel p span{
  display: inline-block;
  height: 1px;
  flex: 1;
  background: rgba(255,255,266,.22);
  margin-left: 8px;
}
.sublevel .sbuImd{
  max-width: 100%;
  max-height: 210px;
  overflow: hidden;
  margin-bottom: 30px;
  /* background-image: url("../assets/images/fenlei.png"); */
  background-size: 100% 100%;
  text-align: center;
}
.sublevel .sbuImd img{
  max-width: 100%;
  max-height: 210px;
}
.rankauto{
  overflow: auto;
  margin-top: 80px;
  height: calc(100% - 100px);
}
.rankauto::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 3px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 2px;
}
.rankauto::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background   : #606266;
}
.rankauto::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: rgba(26,26,26,0.83);
}
@media screen and (max-width:1921px){
  .rankauto{
    height: 770px;
  }
}
@media screen and (max-width:1400px){
  .rankauto{
    height: 740px;
  }
}
  /*.rank .sanji{
      position: absolute;
      left: 200px;
      top: 0;
      padding: 0;
     !* display: none;*!
      transition: .3s;
  }*/
/*搜索弹框*/
.modal_search{
  width: 900px;
  padding: 17px 20px;
  margin: auto;
  background: rgba(255,255,255,0.14);
  border-radius: 14px;
}
.modal_search input{
  flex: 1;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 18px;
  outline: none;
}
.modal_search input::placeholder{
  color: rgba(255,255,255,.5);
}
.modal_search img{
  cursor: pointer;
}
.modal .hotWord{
  width: 900px;
  margin: auto;
  padding: 65px 30px 50px 30px;
  font-size: 16px;
  color: #ffffff;
  flex-wrap: wrap;
}
.modal .hotWord li{
  width: 25%;
  text-align: center;
  padding-bottom: 36px;
  cursor: pointer;
}
.modal .modalGrap{
  width: 900px;
  margin: auto;
}
.modal .modalGrap ul{
  justify-content: space-between;
  padding-top: 30px;
}
.modal .modalGrap ul li{
  width: 118px;
  height: 118px;
  background:rgba(255,255,255,0.28);
  border-radius: 50%;
  position: relative;
}
.modal .modalGrap ul li img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.modal .modalGrap ul li:nth-child(1) img{
  width: 154px;
  height: 103px;
}
.modal .modalGrap ul li:nth-child(2) img{
  width: 116px;
  height: 118px;
}
.modal .modalGrap ul li:nth-child(3) img{
  width: 135px;
  height: 119px;
}
.modal .modalGrap ul li:nth-child(4) img{
  width: 125px;
  height: 111px;
}
.modal .modalGrap ul li:nth-child(5) img{
  width: 89px;
  height: 166px;
}
/*内页样式*/
.insideHeader .box_logo{
  top: 27px;
}
.insideHeader .menu_bar{
  top: 17px;
}
</style>

<template>
  <div style="height: 100%;">
    <div class="hint">【重要提醒】本站已部署盗版取证技术，严禁抓取本站内容用于人工智能语料训练。商业合作联系方式：010-88390682，010-88390717</div>
    <video-index v-if="templateName=='videoIndex'" :indexData="indexData"></video-index>
    <pics-index v-if="templateName=='picsIndex'" :indexData="indexData"></pics-index>
    <maodian-index v-if="templateName=='imgDataIndex'" :indexData="indexData"></maodian-index>
  </div>
</template>

<script>
import {getIndex, getPreIndex} from '@/api/home';
import videoIndex from './index/videoIndex.vue';
import picsIndex from './index/picsIndex.vue';
import maodianIndex from './index/maodianIndex';

export default {
  name: "index",
  components: {
    videoIndex,
    picsIndex,
    maodianIndex
  },
  data() {
    return {
      templateName: "",
      indexData: "",
    };
  },
  created() {
    if(this.$route.path.indexOf("pre")>-1){
      //预览模式
      getPreIndex(this.$route.query.z).then((res) => {
        if(res.redirect=="index")
          this.$router.push({ path: "/index"});
        this.indexData=res;
        this.templateName =res.data.templateName;
      });
    }else{
      getIndex(this.$route.query.type).then((res) => {
        this.indexData=res;
        this.templateName =res.data.templateName;
        this.sortId =res.data.uuid;
      });
    }
  },
};
</script>
<style scoped>
.hint{
    position: fixed;
    z-index: 5;
    font-size: 14px;
    color: #ffffff;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
}
</style>
